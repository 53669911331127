import React, { useState } from 'react';
import './Contact.scss';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Button from '../components/Button';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';

export default function Contact() {
  const data = useStaticQuery(query);
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [sending, setSending] = useState(false);

  const onSubmit = async (data) => {
    setSending(true);

    axios
      .post('https://us-central1-fon-stack.cloudfunctions.net/sendEmail_v2', {
        data: { name: data.name, email: data.email, subject: data.subject, message: data.message },
      })
      .then((r) => {
        addToast('The email was sent successfully', { appearance: 'success', autoDismiss: true });
        setSending(false);
        reset();
      })
      .catch((e) => {
        addToast('An error occurred while sending the email', { appearance: 'error', autoDismiss: true });
        setSending(false);
      });
  };

  return (
    <>
      <div id="contact" style={{ transform: 'translateY(-3rem)' }}>
        &nbsp;
      </div>
      <div className="contact">
        <div className="title mt-5">
          <h2>
            <span className="sub sub-gray">Get in touch</span>
          </h2>
        </div>

        <div className="contact__container">
          <form className="contact__container__form">
            <div className="contact__container__form__row">
              <div className={`input-container ${errors.name ? 'error' : ''}`}>
                <label htmlFor="name">Name</label>
                <input type="text" id="name" placeholder="Enter your name" {...register('name', { required: true })} />
              </div>
              <div className={`input-container ${errors.email ? 'error' : ''}`}>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  {...register('email', { required: true, pattern: /^\S+@\S+\.\S+$/ })}
                />
              </div>
            </div>
            <div className={`input-container ${errors.subject ? 'error' : ''}`}>
              <label htmlFor="subject">Subject</label>
              <input type="text" id="subject" placeholder="What is it about?" {...register('subject', { required: true })} />
            </div>
            <div className={`input-container ${errors.message ? 'error' : ''}`}>
              <label htmlFor="message">Message</label>
              <textarea
                rows="10"
                id="message"
                placeholder="Write here your message"
                {...register('message', { required: true })}
              />
            </div>
            <Button loading={sending} onClick={handleSubmit(onSubmit)} text="Send message" />
          </form>
          <Img
            fluid={data.banner_contact.childImageSharp.fluid}
            draggable={false}
            className="contact__container__banner"
            imgStyle={{ objectFit: 'contain' }}
            alt="banner contact"
          />
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query {
    banner_contact: file(relativePath: { eq: "contact/banner-contact.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
