import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import './EmailButton.scss';


export default function EmailButton({ withoutAnimation }) {
  const data = useStaticQuery(query);

  return (
    <a href="mailto:hello@fonstack.dev" className={`emailButton ${withoutAnimation ? 'withoutAnimation' : ''}`}>
      <Img className="emailButton__image" fluid={data.email_icon.childImageSharp.fluid} alt="email" />
      <p>hello@fonstack.dev</p>
    </a>
  )
}

export const query = graphql`
  query {
    email_icon: file(relativePath: { eq: "icons/email-icon.png" }) {
      childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp_tracedSVG } }
    }
  }
`;