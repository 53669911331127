import React, { useState } from 'react';
import './ProjectCard.scss';
import Img from "gatsby-image";
import Button from "./Button";
import { useStaticQuery, graphql } from "gatsby";
import { Slide } from "react-awesome-reveal";


export default function ProjectCard({ project: { name, fluidImg, stack, description, url }, isOnLeft }) {
  const data = useStaticQuery(query);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div>
        <div className="project-card">
          <Img draggable={false} fluid={fluidImg} className="project-card__image" imgStyle={{ objectFit: 'contain' }} alt={name} />
          <div className="project-card__info">
            <h3>{name}</h3>
            <div className="project-card__info__pills">
              {
                stack.map(tech => (
                  <div key={tech} className="project-card__info__pills__tech-pill">
                    {tech}
                  </div>
                ))
              }
            </div>
            <Button onClick={() => setModalOpen(true)} small={true} outline={true} text='View more' />
          </div>
        </div>

        <div onClick={() => setModalOpen(false)} className={`project-modal-overlay ${modalOpen && 'show'}`}></div>
        <div className={`project-modal ${modalOpen && 'show'}`}>
          <div onClick={() => setModalOpen(false)}>
            <Img draggable={false} fluid={data.closeIcon.childImageSharp.fluid} className="project-modal__close" imgStyle={{ objectFit: 'contain' }} alt="close" />
          </div>
          <h2><span className="sub sub-gray">{name}</span></h2>
          <p className="mb-5">{description}</p>
          {url && (<Button onClick={() => window.open(url, '_blank')} small={true} text='Go to page' />)}
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    closeIcon: file(relativePath: { eq: "icons/close-icon-custom.png" }) {
      childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp_tracedSVG } }
    }
  }
`;