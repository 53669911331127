import React from "react";
import "./Work.scss";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import waves from "../images/waves.svg";
import Button from "../components/Button";
import ProjectCard from "../components/ProjectCard";

export default function Work() {
  const data = useStaticQuery(query);

  const projects = [
    {
      name: "El Dorado",
      stack: ["Flutter", "AWS Serverless", "DynamoDB", "Lambdas"],
      fluidImg: data.eldorado.childImageSharp.fluid,
      description:
        "Hats.finance is a proactive bounty protocol for white hat hackers and auditors, where projects, community members, and stakeholders incentivize protocol security and responsible disclosure.",
      url: "https://app.hats.finance",
    },
    {
      name: "Hats Finance",
      stack: ["React.js", "Blockchain", "Ethers.js", "Web3", "The Graph"],
      fluidImg: data.hats.childImageSharp.fluid,
      description:
        "Software for the Panama’s National Assembly in which the deputies can ask to speak, vote, see the current session and all that in real time using WebSockets.",
    },
    {
      name: "AN507",
      stack: ["Ionic/Angular", "Rest API", "WebSockets", "WebRTC"],
      fluidImg: data.an507.childImageSharp.fluid,
      description:
        "Software for the Panama’s National Assembly in which the deputies can ask to speak, vote, see the current session and all that in real time using WebSockets.",
    },
    {
      name: "Muscle",
      stack: ["React Native", "Expo", "TypeScript", "React Query"],
      fluidImg: data.muscle.childImageSharp.fluid,
      description:
        "The White Labeled reward platform that helps banks reduce loyalty costs, increase card transactions and predict customer behavior —all in one place.",
      url: "https://www.musclepoints.com/",
    },
    {
      name: "Kalea e-Commerce",
      stack: ["Angular", "Firestore", "Cloud Functions"],
      fluidImg: data.kalea.childImageSharp.fluid,
      description:
        "E-commerce for Kalea Market. It has several features such as coupons, offers, pre-orders, dropshippings and orders scheduler. The e-commerce has its own coin for the balance inside the app with which you can use as payment method.",
      url: "https://kaleamarket.com/",
    },
    // {
    //   name: 'Ultraface',
    //   stack: ['Javascript', 'Tensorflow'],
    //   fluidImg: data.ultraface.childImageSharp.fluid,
    //   description:
    //     'Ultraface is a Javascript library for facial authentication. Verifies that the face is in the correct position to later take the photo of the person and send it to a verification algorithm.',
    // },
    {
      name: "Aether e-Commerce",
      stack: ["Angular", "Firestore"],
      fluidImg: data.aetherEcomm.childImageSharp.fluid,
      description:
        "Customizable ecommerce with many features. Coupons, product variations, order tracking, inventory management, and more. All this accompanied by a CMS where you can manage all the content of the store.",
      url: "https://aetherecommerce.web.app/",
    },
    {
      name: "Psiconfort",
      stack: ["Gatsby.js", "Firestore"],
      fluidImg: data.psiconfort.childImageSharp.fluid,
      description: "Landing page of @psiconfort in which you can see all the information about her services.",
      url: "https://psiconfort.com/",
    },
    {
      name: "PGS (CRM)",
      stack: ["Angular", "Rest API"],
      fluidImg: data.pgs.childImageSharp.fluid,
      description:
        "CRM (Customer Relationship Management) for an insurance company in which the entire process of insurance agents, policyholders and insurance policies is handled.",
    },
    {
      name: "Tutorpick",
      stack: ["React.js", "PWA", "GraphQL API"],
      fluidImg: data.tutorpick.childImageSharp.fluid,
      description:
        "Progressive Web App (PWA) where students who need help look for tutors to schedule classes with them, and people who have great knowledge in a subject register so those students can find them. It has a powerful search system for tutors, which helps you find those closest to any location.",
    },
    {
      name: "Ride Unlimited",
      stack: ["React Native", "Firestore"],
      fluidImg: data.unlimited.childImageSharp.fluid,
      description:
        "Mobile application that serves as a tool to connect via Bluetooth to the Unlimited© skateboards. You can add new skateboards, update their firmware, adjust values such as speed, change the ride mode among other options.",
      url: "https://unlimitedxloadedusa.com/",
    },
    {
      name: "uMakeIt",
      stack: ["Angular", "Firestore"],
      fluidImg: data.uMakeIt.childImageSharp.fluid,
      description:
        "uMakeIt! is a sales system for a food branch. The system has a powerful personalization tool for food dishes. Users who are admin have all the system administration tools. All this along with a modern, minimalist and intuitive grapic interface.",
      url: "https://umakeit-crr.firebaseapp.com/",
    },
    {
      name: "Motivapp",
      stack: ["Angular", "Firestore"],
      fluidImg: data.motivapp.childImageSharp.fluid,
      description:
        "Web application based on paid subscriptions for employees of companies where you can see posts (text, audio and video) allowing them to improve their well-being as well as that of their environment, with concrete and visible actions, doing it in a healthy way that will be reflected in their work and personal performance.",
      url: "https://www.motiv-app.com/",
    },
  ];

  return (
    <>
      <div id="work" style={{ transform: "translateY(4rem)" }}>
        &nbsp;
      </div>
      <section className="work">
        <img src={waves} className="waves black" alt="waves" />
        <div className="title mt-6">
          <h2>
            <span className="sub sub-black">Stuff I've done</span>
          </h2>
          <Img
            fluid={data.memoji_works.childImageSharp.fluid}
            className="title__icon"
            imgStyle={{ objectFit: "contain" }}
            alt="carlo's memoji"
          />
        </div>

        <div className="work__projects mb-4">
          <div className="work__projects__column">
            {projects
              .filter((v, i) => !(i % 2))
              .map((project) => (
                <ProjectCard key={project.name} project={project} isOnLeft={true} />
              ))}
          </div>
          <div className="work__projects__column">
            {projects
              .filter((v, i) => i % 2)
              .map((project) => (
                <ProjectCard key={project.name} project={project} />
              ))}
          </div>
        </div>
        <img src={waves} className="waves black inverted" alt="waves" />
      </section>
    </>
  );
}

export const query = graphql`
  query {
    memoji_works: file(relativePath: { eq: "icons/memoji_works.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    an507: file(relativePath: { eq: "projects/an507_image3.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    hats: file(relativePath: { eq: "projects/hats_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    pgs: file(relativePath: { eq: "projects/pgs-image.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    eldorado: file(relativePath: { eq: "projects/eldorado_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    kalea: file(relativePath: { eq: "projects/kalea_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    aetherEcomm: file(relativePath: { eq: "projects/aethereccom_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    psiconfort: file(relativePath: { eq: "projects/psiconfort_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    tutorpick: file(relativePath: { eq: "projects/tutorpick_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    unlimited: file(relativePath: { eq: "projects/unlimited_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    uMakeIt: file(relativePath: { eq: "projects/umakeit_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    motivapp: file(relativePath: { eq: "projects/motivapp_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    muscle: file(relativePath: { eq: "projects/muscle_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ultraface: file(relativePath: { eq: "projects/ultraface_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
