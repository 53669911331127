import React from "react";
import "./Skills.scss";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import SkillCard from "../components/SkillCard";
import { Fade } from "react-awesome-reveal";

export default function Skills() {
  const data = useStaticQuery(query);

  const skills = [
    {
      id: 1,
      name: "Frontend Developer",
      fluidImg: data.frontend_icon.childImageSharp.fluid,
      experience: `${new Date().getFullYear() - 2017}+ years`,
      items: ["HTML - Sass", "React.js", "TypeScript", "JavaScript", "Angular", "Flutter", "React Native"],
    },
    {
      id: 2,
      name: "Blockchain and Web3",
      fluidImg: data.blockchain_icon.childImageSharp.fluid,
      experience: `${new Date().getFullYear() - 2021}+ year`,
      items: ["Ethers.js", "Wagmi", "The Graph", "Hardhat", "Solidity"],
    },
    {
      id: 3,
      name: "UI/UX Designer",
      fluidImg: data.designer_icon.childImageSharp.fluid,
      experience: `${new Date().getFullYear() - 2019}+ years`,
      items: ["Figma", "Photoshop", "Adobe XD", "Illustrator"],
    },
    {
      id: 4,
      name: "Backend Developer",
      fluidImg: data.backend_icon.childImageSharp.fluid,
      experience: `${new Date().getFullYear() - 2020}+ years`,
      items: [
        "Node.js",
        "AWS Serverless",
        "GraphQL",
        "Express",
        "MongoDB",
        "Firebase/Firestore",
        "Google cloud",
        "SQL",
        "Rest API",
      ],
    },
  ];

  return (
    <>
      <div id="skills" style={{ transform: "translateY(10rem)" }}>
        &nbsp;
      </div>
      <section className="skills">
        <div className="title mt-5">
          <h2>
            <span className="sub sub-gray">Skills & Tools</span>
          </h2>
          <Img
            fluid={data.memoji_skills.childImageSharp.fluid}
            className="title__icon"
            imgStyle={{ objectFit: "contain" }}
            alt="carlo's memoji"
          />
        </div>

        <Fade triggerOnce={true}>
          <div className="skills__container">
            <div className="skills__container__column">
              {[skills[0], skills[2]].map((skill) => (
                <div className="skills__container__column__card" key={skill.id}>
                  <SkillCard skill={skill} />
                </div>
              ))}
            </div>
            <div className="skills__container__column">
              {[skills[1], skills[3]].map((skill) => (
                <div className="skills__container__column__card" key={skill.id}>
                  <SkillCard skill={skill} />
                </div>
              ))}
            </div>
          </div>
        </Fade>
      </section>
    </>
  );
}

export const query = graphql`
  query {
    memoji_skills: file(relativePath: { eq: "icons/memoji_skills.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    blockchain_icon: file(relativePath: { eq: "skills/blockchain_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    backend_icon: file(relativePath: { eq: "skills/backend_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    frontend_icon: file(relativePath: { eq: "skills/frontend_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    designer_icon: file(relativePath: { eq: "skills/designer_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
