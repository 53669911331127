import React, { useState } from 'react';
import './SkillCard.scss';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function SkillCard({ skill: { name, fluidImg, experience, items } }) {
  const data = useStaticQuery(query);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="skill-card">
      <div className="skill-card__title" onClick={() => setIsOpen(!isOpen)}>
        <div className="skill-card__title__cont">
          <Img fluid={fluidImg} className="skill-card__title__cont__image" imgStyle={{ objectFit: 'contain' }} alt={name} />
          <p>{name} <span>({experience})</span></p>
        </div>
        <Img fluid={data.arrow.childImageSharp.fluid} className={`skill-card__title__arrow ${isOpen && 'up'}`} imgStyle={{ objectFit: 'contain' }} alt={isOpen ? 'close' : 'open'} />
      </div>
      <div className={`skill-card__items ${isOpen && 'open'}`}>
        {items.map(item => (
          <p key={item} className="skill-card__items__item">{item}</p>
        ))}
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    arrow: file(relativePath: { eq: "icons/arrow_right.png" }) {
      childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp_tracedSVG } }
    }
  }
`;