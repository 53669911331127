import React from "react";
import { Helmet } from "react-helmet";
import { ToastProvider } from "react-toast-notifications";
import Navbar from "../components/Navbar";
import Home from "../sections/Home";
import AboutMe from "../sections/AboutMe";
import Skills from "../sections/Skills";
import Work from "../sections/Work";
import Contact from "../sections/Contact";
import Footer from "../components/Footer";
import "./index.scss";

const IndexPage = () => {
  return (
    <ToastProvider placement="bottom-right">
      <main id="home">
        <Helmet>
          <title>Carlos Fontes (fonstack) - Full Stack Web3 Developer</title>
          <meta
            property="og:image"
            content="https://firebasestorage.googleapis.com/v0/b/fon-stack.appspot.com/o/open-graph_v2.png?alt=media&token=2e35153d-1384-4efd-94be-37e8d435d8d5"
          />
          <meta property="og:site_name" content="fonstack.dev" />
          <meta property="og:title" content="Carlos Fontes (fonstack)" />
          <meta
            property="og:description"
            content="Personal Full Stack Web3 Developer portfolio. Transforming the world with digital solutions."
          />
          <meta
            name="twitter:image"
            content="https://firebasestorage.googleapis.com/v0/b/fon-stack.appspot.com/o/open-graph_v2.png?alt=media&token=2e35153d-1384-4efd-94be-37e8d435d8d5"
          />
          <meta
            name="twitter:image:src"
            content="https://firebasestorage.googleapis.com/v0/b/fon-stack.appspot.com/o/open-graph_v2.png?alt=media&token=2e35153d-1384-4efd-94be-37e8d435d8d5"
          />
          <meta name="twitter:image:alt" content="fonstack.dev" />
          <meta name="twitter:title" content="Carlos Fontes (fonstack)" />
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:description"
            content="Personal Full Stack Web3 Developer portfolio. Transforming the world with digital solutions."
          />
          <meta
            name="description"
            content="Personal Full Stack Web3 Developer portfolio. Transforming the world with digital solutions. A Systems Engineer, Fullstack and Blockchain Developer in Lisbon. I specialize in Frontend Development and UI/UX Design."
          />

          <link rel="canonical" href="https://fonstack.dev" />
          <script src="https://smtpjs.com/v3/smtp.js"></script>
          <html lang="en" />
        </Helmet>
        <Navbar />
        <Home />
        <AboutMe />
        <Skills />
        <Work />
        <Contact />
        <Footer />
      </main>
    </ToastProvider>
  );
};

export default IndexPage;
