import React, { useState } from "react";
import "./Footer.scss";
import EmailButton from "./EmailButton";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import separator from "../images/icons/sub-gray-big.png";

export default function Footer() {
  const data = useStaticQuery(query);

  return (
    <footer className="footer">
      <img className="footer__separator" src={separator} alt="separator" />
      <div className="footer__container">
        <div className="footer__container__social">
          {/* <a
            href="https://www.instagram.com/carlosfontest/"
            target="_blank"
            className="footer__container__social__item"
            rel="noopener">
            <Img
              fluid={data.instagramIcon.childImageSharp.fluid}
              draggable={false}
              className="footer__container__social__item__icon"
              imgStyle={{ objectFit: 'contain' }}
              alt="instagram"
            />
          </a> */}
          <a
            href="https://www.linkedin.com/in/carlosfontest/"
            target="_blank"
            className="footer__container__social__item"
            rel="noopener">
            <Img
              fluid={data.linkdnIcon.childImageSharp.fluid}
              draggable={false}
              className="footer__container__social__item__icon"
              imgStyle={{ objectFit: "contain" }}
              alt="linkdn"
            />
          </a>
          <a href="https://github.com/fonstack" target="_blank" className="footer__container__social__item" rel="noopener">
            <Img
              fluid={data.githubIcon.childImageSharp.fluid}
              draggable={false}
              className="footer__container__social__item__icon"
              imgStyle={{ objectFit: "contain" }}
              alt="github"
            />
          </a>
        </div>
        <div className="footer__container__copy">
          <Img
            fluid={data.fonstackLogo.childImageSharp.fluid}
            draggable={false}
            className="footer__container__copy__logo"
            imgStyle={{ objectFit: "contain" }}
            alt="fonstack logo"
          />
          <p>{new Date().getFullYear()} &copy; All rights reserved</p>
        </div>
        <EmailButton withoutAnimation={true} />
      </div>
    </footer>
  );
}

export const query = graphql`
  query {
    instagramIcon: file(relativePath: { eq: "icons/instagram-icon.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    linkdnIcon: file(relativePath: { eq: "icons/linkdn-icon.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    githubIcon: file(relativePath: { eq: "icons/github-icon.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    fonstackLogo: file(relativePath: { eq: "icons/fonstack-logo.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
