import React from "react";
import "./Home.scss";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Button from "../components/Button";
import { JackInTheBox } from "react-awesome-reveal";

export default function Home() {
  const data = useStaticQuery(query);

  return (
    <JackInTheBox triggerOnce={true}>
      <section className="home">
        <div className="home__presentation-card">
          <div className="home__presentation-card__greating">
            <Img
              className="handshake"
              fluid={data.handshake_icon.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
              alt="handshake"
            />
            <div className="home__presentation-card__greating__text">
              <p>Hello, I am</p>
              <p>Carlos</p>
            </div>
          </div>
          <Img className="my-face" fluid={data.my_face.childImageSharp.fluid} alt="carlo's face" />
        </div>
        <h2>
          <span className="sub sub-gray">Transforming</span> the world with digital solutions.
        </h2>
        <p className="my-description">
          a <strong>Systems Engineer</strong> and <strong>Full Stack Web3 and Blockchain Developer</strong> in Lisbon. I'm
          currently <strong>Head of Developers</strong> and <strong>Lead Frontend Web3 Developer</strong>.
        </p>
        <Button link="#work" text="View my work" />
        <a href="#about" className="home__scroll-down">
          <div className="mouse">
            <div className="wheel"></div>
          </div>
          <p>Scroll down</p>
        </a>
      </section>
    </JackInTheBox>
  );
}

export const query = graphql`
  query {
    handshake_icon: file(relativePath: { eq: "icons/handshake_icon.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    my_face: file(relativePath: { eq: "my_face.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
