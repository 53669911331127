import React, { useState } from 'react';
import './Navbar.scss';
import EmailButton from './EmailButton';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";


export default function Navbar() {
  const data = useStaticQuery(query);

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav>
      <EmailButton />
      <ul className="ul-desktop">
        <li><a href="#home">Home</a></li>
        <li><a href="#about">About me</a></li>
        <li><a href="#skills">Skills</a></li>
        <li><a href="#work">Work</a></li>
        <li><a href="#contact">Contact me</a></li>
      </ul>
      <div className="mobile-menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
        <Img draggable={false} fluid={data.menuIcon.childImageSharp.fluid} className="icon" imgStyle={{ objectFit: 'contain' }} alt="menu" />
      </div>

      <div className={`mobile-menu ${menuOpen && 'open'}`}>
        <ul>
          <li onClick={() => setMenuOpen(!menuOpen)}>
            <a href="#home">
              <Img draggable={false} fluid={data.homeIcon.childImageSharp.fluid} className="mobile-menu__list-icon" imgStyle={{ objectFit: 'contain' }} alt="home" />
              Home
            </a>
          </li>
          <li onClick={() => setMenuOpen(!menuOpen)}>
            <a href="#about">
              <Img draggable={false} fluid={data.aboutIcon.childImageSharp.fluid} className="mobile-menu__list-icon" imgStyle={{ objectFit: 'contain' }} alt="about me" />
              About
            </a>
          </li>
          <li onClick={() => setMenuOpen(!menuOpen)}>
            <a href="#skills">
              <Img draggable={false} fluid={data.skillsIcon.childImageSharp.fluid} className="mobile-menu__list-icon" imgStyle={{ objectFit: 'contain' }} alt="skills" />
              Skills
            </a>
          </li>
          <li onClick={() => setMenuOpen(!menuOpen)}>
            <a href="#work">
              <Img draggable={false} fluid={data.portfolioIcon.childImageSharp.fluid} className="mobile-menu__list-icon" imgStyle={{ objectFit: 'contain' }} alt="my works" />
              Work
            </a>
          </li>
          <li onClick={() => setMenuOpen(!menuOpen)}>
            <a href="#contact">
              <Img draggable={false} fluid={data.contactIcon.childImageSharp.fluid} className="mobile-menu__list-icon" imgStyle={{ objectFit: 'contain' }} alt="contact" />
              Contact
            </a>
          </li>
        </ul>
        <div onClick={() => setMenuOpen(!menuOpen)}>
          <Img draggable={false} fluid={data.closeIcon.childImageSharp.fluid} className="mobile-menu__close" imgStyle={{ objectFit: 'contain' }} alt="close" />
        </div>
      </div>
    </nav>
  )
}

export const query = graphql`
  query {
    menuIcon: file(relativePath: { eq: "icons/menu-icon.png" }) {
      childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp_tracedSVG } }
    },
    closeIcon: file(relativePath: { eq: "icons/close-icon.png" }) {
      childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp_tracedSVG } }
    },
    homeIcon: file(relativePath: { eq: "icons/home-icon.png" }) {
      childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp_tracedSVG } }
    },
    aboutIcon: file(relativePath: { eq: "icons/about-icon.png" }) {
      childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp_tracedSVG } }
    },
    skillsIcon: file(relativePath: { eq: "icons/skills-icon.png" }) {
      childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp_tracedSVG } }
    },
    portfolioIcon: file(relativePath: { eq: "icons/portfolio-icon.png" }) {
      childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp_tracedSVG } }
    },
    contactIcon: file(relativePath: { eq: "icons/contact-icon.png" }) {
      childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp_tracedSVG } }
    },
  }
`;