import React from "react";
import "./AboutMe.scss";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import waves from "../images/waves.svg";
import bgIndicator1 from "../images/about/indicator_1.svg";
import bgIndicator2 from "../images/about/indicator_2.svg";
import bgService1 from "../images/about/service_1.svg";
import bgService2 from "../images/about/service_2.svg";
import bgService3 from "../images/about/service_3.svg";
import Button from "../components/Button";
import myResume from "../images/my_resume.pdf";
import { Fade } from "react-awesome-reveal";

export default function AboutMe() {
  const data = useStaticQuery(query);

  return (
    <section className="about" id="about">
      <img src={waves} className="waves black" alt="waves" />
      <div className="title mt-6">
        <h2>
          <span className="sub sub-black">A bit about me</span>
        </h2>
        <Img
          fluid={data.memoji_about.childImageSharp.fluid}
          className="title__icon"
          imgStyle={{ objectFit: "contain" }}
          alt="carlo's memoji"
        />
      </div>

      <Fade triggerOnce={true}>
        <div className="about__info">
          <div className="about__info__indicators">
            <div className="about__info__indicators__item">
              <div className="about__info__indicators__item__number">
                <img src={bgIndicator1} alt="bg indicator" />
                <p>{new Date().getFullYear() - 2017}+</p>
              </div>
              <p className="about__info__indicators__item__text">
                Years <br /> experience
              </p>
            </div>
            <div className="about__info__indicators__item">
              <div className="about__info__indicators__item__number">
                <img src={bgIndicator2} alt="bg indicator" />
                <p>25+</p>
              </div>
              <p className="about__info__indicators__item__text">
                Completed <br /> projects
              </p>
            </div>
          </div>
          <div className="about__info__text">
            <p className="about__info__text__title-muted">_ Problem solver | UX Maniac | Blockchain Lover</p>
            <p className="about__info__text__first">
              I enjoy solving logic problems with clean and scalable solutions. I have a genuine passion for UI/UX design. And I
              really love the idea of a world supported by Blockchain.
            </p>
            <p className="about__info__text__second">
              I am a Systems Engineer living in Lisbon and currently working as a Full Stack Web3 ans blockchain developer. I
              mainly focus on Front-End and my designs are characterized by offering a good user experience along by Responsive
              Web Design and modern Animations.
            </p>
            <div className="mb-5">
              <Button link={myResume} download="CarlosFontes_resume.pdf" text="Download Resume" />
            </div>
          </div>
        </div>
      </Fade>

      <div className="about__services">
        <div className="about__services__service">
          <img src={bgService1} alt="bg service" className="about__services__service__bg" />
          <div className="about__services__service__content">
            <Img
              fluid={data.service1.childImageSharp.fluid}
              className="about__services__service__content__image"
              imgStyle={{ objectFit: "contain" }}
              alt="strategy & direction"
            />
            <p className="about__services__service__content__title">Strategy & Direction</p>
            <p className="about__services__service__content__text">
              Defining the problem, identifying the scope and finally, organising the roadmap to get 100% of every project.
            </p>
          </div>
        </div>
        <div className="about__services__service">
          <img src={bgService2} alt="bg service" className="about__services__service__bg" />
          <div className="about__services__service__content">
            <Img
              fluid={data.service2.childImageSharp.fluid}
              className="about__services__service__content__image"
              imgStyle={{ objectFit: "contain" }}
              alt="ui/ux design"
            />
            <p className="about__services__service__content__title">UI/UX Design</p>
            <p className="about__services__service__content__text">
              Websites, applications or other design related tasks. I love tackling digital problems and help fixing them.
            </p>
          </div>
        </div>
        <div className="about__services__service">
          <img src={bgService3} alt="bg service" className="about__services__service__bg" />
          <div className="about__services__service__content">
            <Img
              fluid={data.service3.childImageSharp.fluid}
              className="about__services__service__content__image"
              imgStyle={{ objectFit: "contain" }}
              alt="web/mobile apps"
            />
            <p className="about__services__service__content__title">Web/Mobile apps</p>
            <p className="about__services__service__content__text">
              Either a mobile app or a web app, we can work together. I enjoy seeing my apps on every existent platform.
            </p>
          </div>
        </div>
      </div>
      <img src={waves} className="waves black inverted" alt="waves" />
    </section>
  );
}

export const query = graphql`
  query {
    memoji_about: file(relativePath: { eq: "icons/memoji_about.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    service1: file(relativePath: { eq: "about/strategy_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    service2: file(relativePath: { eq: "about/design_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    service3: file(relativePath: { eq: "about/web-mobile_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
