import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import './Button.scss';


export default function Button({ outline, small, text, link, download, onClick, loading }) {
  const data = useStaticQuery(query);

  return (
    <a className={`button ${(outline && 'outline') || ''} ${(small && 'small') || ''}`} href={link} download={download} onClick={loading ? null : onClick} >
      {
        loading ? (
          <div className="lds-dual-ring"></div>
        ) : (
          <>
            <p>{text}</p>
            <Img className="button__image" fluid={data.arrow.childImageSharp.fluid} alt="go" />
          </>
        )
      }
    </a>
  )
}

export const query = graphql`
  query {
    arrow: file(relativePath: { eq: "icons/arrow_right.png" }) {
      childImageSharp { fluid { ...GatsbyImageSharpFluid_withWebp_tracedSVG } }
    }
  }
`;